<template>
    <section class="menu-widget">
        <!-- ordinateur -->
        <span class="computer">
            <!-- gauche -->
            <div id="menu-1" class="menu d-flex" @click="servicePro()">
                <span class="text mx-2">SERVICES PRO</span> 
                <img class="mx-2" src="../../../assets/images/import/cpts/perso-2.png" alt="">
            </div>
            <div id="menu-2" class="menu d-flex" @click="servicePatient()">
                <span class="text mx-2">SERVICES PATIENTS</span> 
                <img class="mx-2" src="../../../assets/images/import/cpts/perso-3.png" alt="">
            </div>
            <!-- droite -->
            <div id="menu-3" class="menu d-flex ">
                <img class="mx-2" src="../../../assets/images/import/cpts/search.png" alt="">
                <span class="text mx-2">TROUVER UN MEDECIN</span> 
            </div>
            <div id="menu-4" class="menu d-flex ">
                <img class="mx-2" src="../../../assets/images/import/cpts/phone.png" alt="">
                <span class="text mx-2">NUMEROS D'URGENCE</span> 
            </div>
        </span>

        <!-- mobile -->
        <span>
            <b-dropdown class="widget-button" dropup size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <button class="btn btn-primary btn-social-icon mobile" @click="icon = !icon">
                        <i v-if="icon" class="mdi mdi-close"></i>
                        <i v-else class="mdi mdi-menu"></i>
                    </button>
                </template>
                <b-dropdown-item v-for="menu in menus" :key="menu.id" @click="gotoUrl(menu.url)">
                    <span class="d-flex">
                        <img class="mx-2" style="width:1.5rem;height:1.5rem" :src="require(`../../../assets/images/import/cpts/${menu.image}`)" alt="">
                        <p class="m-0">{{menu.title}}</p>
                    </span>
                </b-dropdown-item>
            </b-dropdown>
        </span>


    </section>
</template>
<script>
export default {
    name:"menu-widget",
    data(){
        return{
            icon:false,
            menus:[
                { title:'SERVICES PRO', image:'perso-2.png', url:'service-pro'},
                { title:'SERVICES PATIENTS', image:'perso-3.png', url:'service-patient'},
                { title:'TROUVER UN MEDECIN', image:'search.png'},
                { title:'NUMEROS D\'URGENCE', image:'phone.png'},
            ]
        }
    },
    methods:{
        gotoUrl(url){
            this.$router.push(url)
        },
        servicePro(){
            this.$router.push('service-pro')
        },
        servicePatient(){
            this.$router.push('service-patient')
        }

    }
}
</script>
<style lang="scss">
.computer{
    .menu {
        cursor: pointer;
        background: white;
        border: 3px solid #40B78F;
        border-radius: 26px;
        padding:0.5rem;
        .text{
            color:#40B78F;
            line-height:none !important
        }
    }
    #menu-1{
        position: absolute;
        top: 2rem;
        z-index: 30;
        transform: translateX(-8rem);
        transition:0.5s ease-out
    }
    #menu-1:hover{
        position: absolute;
        top: 2rem;
        z-index: 30;
        transform: translateX(-1rem);
    }
    
    #menu-2{
        position: absolute;
        top: 6rem;
        z-index: 30;
        transform: translateX(-11rem);
        transition:0.5s ease-out
    }
    #menu-2:hover{
        position: absolute;
        top: 6rem;
        z-index: 30;
        transform: translateX(-1rem);
    }
    
    #menu-3{
        position: absolute;
        top: 2rem;
        right:0;
        z-index: 30;
        transform: translateX(12.2rem);
        transition:0.5s ease-out
    }
    #menu-3:hover{
        position: absolute;
        top: 2rem;
        z-index: 30;
        transform: translateX(1rem);
    }
    
    #menu-4{
        position: absolute;
        top: 6rem;
        right:0;
        z-index: 30;
        transform: translateX(12rem);
        transition:0.5s ease-out
    }
    #menu-4:hover{
        position: absolute;
        top: 6rem;
        z-index: 30;
        transform: translateX(1rem);
    }
}


// mobile
.widget-button{
    position:fixed;
    right: 1px;
    bottom: 1px;
    z-index:30;

    ul{
        background:none;
        border:none;
        transition:0.5s ease-out;
        li{
            background:#fff;
            margin: 5px 0px;
            border:2px #40B78F solid;
            border-radius:20px;
        }
    }
    ul .show{
        transition:0.5s ease-in-out
    }
    p{
        color:#40B78F
    }
}

.widget-button button {
    padding:0px !important
}

</style>